import React from 'react';

import styles from './Modal.module.css';

export const ErrorModal = ({ onClose }) => (
  <div className={styles.wrapper}>
    <div className={styles.modal}>
      <div className={styles.emoji}>🤔</div>
      <div className={styles.title}>щось пішло не так..</div>
      <div className={styles.text}>
        хм, чомусь не вдається тебе записати, спробуй ще раз
      </div>
      <button className={styles.button} onClick={onClose}>
        Добре, зараз спробую
      </button>
    </div>
  </div>
);
