import React from 'react';

import styles from './SpecialCategoryHeader.module.css';

export const SpecialCategoryHeader = ({ label, icon }) => (
  <div className={styles.wrapper}>
    {Array.from({ length: 20 }).map((_, index) => (
      <div key={index}>
        <div>{label}</div>
        <img src={icon} alt="icon-special-category" />
      </div>
    ))}
  </div>
);
