import classNames from 'classnames';
import React from 'react';

import { iconEmail, iconInstagram, iconTelegram } from '../../../assets';
import { INPUTS_OPTIONS } from '../constants';
import styles from './FormInput.module.css';
import { validate } from './validation';

const INPUTS_PLACEHOLDERS = {
  [INPUTS_OPTIONS.email]: 'tviyemail@gmail.com',
  [INPUTS_OPTIONS.instagram]: 'tviynick',
  [INPUTS_OPTIONS.telegram]: 'tviynick',
};

export const FormInput = ({
  value,
  setValue,
  option,
  setOption,
  error,
  setError,
}) => {
  const onChangeOption = (option) => () => {
    setOption(option);
    value && validate(value, option, setError);
  };

  const onChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    validate(newValue, option, setError);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.options}>
        <div
          className={classNames(styles.option, {
            [styles.selected]: option === INPUTS_OPTIONS.email,
          })}
          onClick={onChangeOption(INPUTS_OPTIONS.email)}
        >
          <img src={iconEmail} alt="icon-email" />
        </div>
        <div
          className={classNames(styles.option, {
            [styles.selected]: option === INPUTS_OPTIONS.instagram,
          })}
          onClick={onChangeOption(INPUTS_OPTIONS.instagram)}
        >
          <img src={iconInstagram} alt="icon-instagram" />
        </div>
        <div
          className={classNames(styles.option, {
            [styles.selected]: option === INPUTS_OPTIONS.telegram,
          })}
          onClick={onChangeOption(INPUTS_OPTIONS.telegram)}
        >
          <img src={iconTelegram} alt="icon-telegram" />
        </div>
      </div>
      <input
        value={value}
        onChange={onChange}
        placeholder={INPUTS_PLACEHOLDERS[option]}
      />
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};
