import React from 'react';

import styles from './Page.module.css';

export const Page = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Privacy policy</div>
      <div className={styles.block}>Effective date: 01.06.2024</div>
      <div className={styles.block}>
        This Privacy Policy explains how Tviy Donut (“we”, “us”, or “our”)
        collects, uses, and shares personal data when you use our mobile
        application, Tviy Donut. By using the app, you agree to the terms of
        this Privacy Policy.
      </div>

      <div>
        By using our website (https://www.tviydonut/), and/or Tviy Donut mobile
        applications (hereinafter collectively referred to as the "Service" or
        "Services"), you accept this Policy and give us your consent to process
        your personal data as described in this document.
      </div>

      <div className={styles.block}>
        <div className={styles.header}>1. Information We Collect</div>
        <div>
          We may collect personal information you provide us directly when you:
        </div>

        <div>
          {' '}
          - Create an account (contact data, such as your first and last name,
          photograph or picture, apple id, social network usernames, email
          address, phone number and any other information that you add to your
          account profile).
        </div>
        <div>
          {' '}
          - Choose to share access with us Phone contacts data, such as the
          names and telephone numbers of contacts in your phone’s contacts list.
          You may revoke such access at any time in your phone settings.
        </div>
        <div>
          - Contact us through the Service, social media, or otherwise
          (communications data based on our exchanges with you).
        </div>
        <div>
          - Complete your donations through the Service (transactional data that
          applies only to your charity transactions, bank token in encrypted
          form, id of bank accounts).
        </div>
        <div>
          {' '}
          - Use features that require data input (e.g., photos, messages, names
          or other information).
        </div>

        <div className={styles.block}>
          We may combine personal information we receive from you with personal
          information we obtain from other sources, such as: public sources,
          such as government agencies, public records, social media platforms,
          and other publicly available sources; private sources, such as data
          providers, social media platforms, and data licensors; marketing
          partners, such as joint marketing partners and event co-sponsors.
        </div>

        <div className={styles.block}>
          Other data not specifically listed here can also be collected, which
          we will use only as described in this Privacy Policy or as otherwise
          disclosed at the time of collection.
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.header}>2. How We Use Your Information</div>
        <div className={styles.block}>
          We may use your personal information for the following purposes or as
          otherwise described at the time of collection:
        </div>

        <div> - Provide, operate and improve the Service and our business.</div>

        <div>
          - Facilitate your invitations to friends or contacts who you want to
          connect with on, or invite to join, the Service.
        </div>

        <div>
          - Communicate with you about the Service, including by sending
          Service-related announcements, updates, security alerts, and support
          and administrative messages, communicate with you about events or
          contests in which you participate; understand your needs and
          interests, and personalize your experience with the Service and our
          communications and provide support for the Service, and respond to
          your requests, questions and feedback.
        </div>

        <div>
          - We may send you direct marketing communications and may personalize
          these messages based on your needs and interests.
        </div>

        <div>
          - We may use your personal information to analyze your usage of the
          Service, improve the Service, improve the rest of our business, help
          us understand user activity on the Service, including which pages are
          most and least visited and how visitors move around the Service, as
          well as user interactions with our emails, and to develop new products
          and services.
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.header}>3. Sharing Your Information</div>
        <div>
          We may need to share your personal data with others to ensure the
          proper provision of the Services. In such cases, we will comply with
          applicable laws aimed at ensuring the confidentiality of your personal
          data. By using our Service, you consent to sharing the information we
          collect about you, including personal data, with any of our partners
          who ensure the proper provision of the Service.
        </div>

        <div>
          We may collect analytics or use third-party analytics tools to help us
          measure trends in traffic and usage of the Services, and to understand
          the demographics and behaviors of our users, how users interact with
          our Service.
        </div>

        <div>
          We may share your information with the following recipients:
          professional consultants, service providers, partners, for the
          purposes described in this Policy. In addition, we may share your
          information with competent law enforcement, regulatory, governmental
          authorities, courts or other third parties when such disclosure is
          necessary to comply with our legal obligation.
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.header}>4. Data Retention</div>
        <div>
          We generally retain personal information to fulfill the purposes for
          which we collected it, including for the purposes of satisfying any
          legal, accounting, or reporting requirements, to establish or defend
          legal claims, or for fraud prevention purposes. To determine the
          appropriate retention period for personal information, we may consider
          factors such as the amount, nature, and sensitivity of the personal
          information, the potential risk of harm from unauthorized use or
          disclosure of your personal information, the purposes for which we
          process your personal information and whether we can achieve those
          purposes through other means, and the applicable legal requirements.
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.header}>5. Security of Your Information</div>
        <div>
          We employ a number of technical, organizational and physical
          safeguards designed to protect the personal information we collect.
          However, security risk is inherent in all internet and information
          technologies and we cannot guarantee the security of your personal
          information.
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.header}>6. Links to third-parties</div>
        <div>
          Our Service may contain links to additional third-parties websites,
          mobile apps and other online services operated by third parties. We
          are not responsible for the content of such third-party websites and
          mobile applications. We encourage you to read the privacy policies of
          the other websites, mobile applications and online services you use.
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.header}>7. Your Rights</div>
        <div>
          As a user of the Service, you have certain rights regarding your
          personal data under applicable data protection laws. Below is a
          detailed explanation of your rights and how to exercise them:
        </div>
        <div>
          {' '}
          - You have the right to request access to the personal data we hold
          about you.
        </div>
        <div>
          {' '}
          - You have the right to request correction of any inaccurate or
          incomplete personal data we hold about you. This ensures that your
          information is accurate and up to date.
        </div>
        <div>
          {' '}
          - You have the right to request the deletion of your personal data
          under certain circumstances, such as: when the data is no longer
          necessary for the purposes for which it was collected; when you
          withdraw your consent, and there are no other legal grounds for
          processing your data; when the data must be deleted to comply with
          legal obligations.
        </div>

        <div>
          - You have the right to request the restriction of processing your
          personal data in the following situations: if you contest the accuracy
          of the data, we will restrict processing until the data’s accuracy is
          verified; if the processing is unlawful, and you prefer restriction
          over deletion; if we no longer need your data for processing, but you
          require it to establish, exercise, or defend legal claims; if you have
          objected to processing, pending verification of whether our legitimate
          grounds override yours.
        </div>

        <div>
          You have the right to withdraw your consent at any time. Withdrawal of
          consent will not affect the lawfulness of processing conducted prior
          to your withdrawal, but it may limit certain functionalities of the
          app.
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.header}>8. Changes to This Privacy Policy</div>
        <div>
          We may update this Privacy Policy from time to time on our website.
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.header}>9. Contact Us</div>

        <div>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us:
        </div>

        <div>Email: tviydonut@gmail.com</div>
      </div>
    </div>
  );
};
