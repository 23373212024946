import React from 'react';

import styles from './Modal.module.css';

export const SuccessModal = ({ onClose }) => (
  <div className={styles.wrapper}>
    <div className={styles.modal}>
      <div className={styles.emoji}>🫶</div>
      <div className={styles.title}>все вийшло!</div>
      <div className={styles.text}>
        ми тебе вже додали до списку, тому як тільки все буде готове, то
        обовʼязково тобі напишемо!
      </div>
      <button className={styles.button} onClick={onClose}>
        Клас, чекатиму!
      </button>
    </div>
  </div>
);
