import { INPUTS_OPTIONS } from '../constants';

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validate = (value, option, setError) => {
  switch (option) {
    case INPUTS_OPTIONS.email:
      return setError(
        emailRegex.test(value)
          ? ''
          : 'будь-ласка, введи корректну електронну пошту',
      );

    case INPUTS_OPTIONS.telegram:
    case INPUTS_OPTIONS.instagram: {
      if (value.includes(' ')) {
        return setError('нікнейм не має містити пробіли');
      }

      return setError('');
    }

    default:
      return setError('');
  }
};
