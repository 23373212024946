import React from 'react';

import { SpecialCategoryHeader } from '../common/components';
import { iconHalfLogo, iconHand } from './assets';
import { Form } from './components/Form/Form';
import styles from './Page.module.css';

export const Page = () => {
  return (
    <>
      <SpecialCategoryHeader label={'привіт'} icon={iconHand} />
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>хей!</div>
          <div className={styles.title}>я - твій донат!</div>

          <div className={styles.subtitle}>
            моя мета - зробити донати регулярними і фановими, а також допомагати
            волонтеру 🫂
          </div>
        </div>

        <Form />

        <div className={styles.info}>
          ...і, до речі,{' '}
          <span className={styles.highlighted}>
            кількість місць на перший перегляд обмежена 🤫
          </span>
        </div>

        <img className={styles.logo} src={iconHalfLogo} alt="icon-logo" />
      </div>
    </>
  );
};
