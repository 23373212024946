import React from 'react';

import styles from './NotFound.module.css';

export const NotFound = () => (
  <div className={styles.wrapper}>
    <div className={styles.title}>404</div>
    <div className={styles.title}>Not Found</div>
  </div>
);
