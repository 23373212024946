import classNames from 'classnames';
import { addDoc, collection } from 'firebase/firestore';
import React, { useState } from 'react';

import { db } from '../../../firebase';
import { INPUTS_OPTIONS } from './constants';
import styles from './Form.module.css';
import { FormInput } from './FormInput';
import { ErrorModal, SuccessModal } from './Modals';

export const Form = () => {
  const [option, setOption] = useState(INPUTS_OPTIONS.email);
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  const onClick = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, 'early-access-data'), {
        [option]: value,
      });

      setSuccessModalVisible(true);
    } catch (e) {
      setErrorModalVisible(true);
    }
  };

  const onCloseSuccessModal = () => {
    setSuccessModalVisible(false);
  };

  const onCloseErrorModal = () => {
    setErrorModalVisible(false);
  };

  return (
    <>
      <div className={styles.form}>
        <div className={styles.inner}>
          <div>
            хоч ми досі в стадії розробки, але вже зовсім скоро плануємо
            запуститись!
          </div>

          <div>
            саме тому, якщо хочеш{' '}
            <span className={styles.highlighted}>першим</span> покористуватись (
            <span className={styles.highlighted}>
              {' '}
              і звичайно ж отримати від нас деякі прикольчікі
            </span>
            ), то залишай нам свій нік або пошту, і як тільки, то ми одразу тобі
            напишемо з новинами 🥹
          </div>

          <FormInput
            value={value}
            setValue={setValue}
            option={option}
            setOption={setOption}
            error={error}
            setError={setError}
          />

          <button
            disabled={!value || !!error}
            className={classNames(styles.button, {
              [styles.disabled]: !value || !!error,
            })}
            onClick={onClick}
          >
            Надіслати
          </button>
        </div>
      </div>

      {successModalVisible && <SuccessModal onClose={onCloseSuccessModal} />}
      {errorModalVisible && <ErrorModal onClose={onCloseErrorModal} />}
    </>
  );
};
