// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC06_gFWKg6xzWVYf83bYuNURntJ1YAYUY',
  authDomain: 'tviydonut-1cf7e.firebaseapp.com',
  projectId: 'tviydonut-1cf7e',
  storageBucket: 'tviydonut-1cf7e.appspot.com',
  messagingSenderId: '260381812971',
  appId: '1:260381812971:web:d72efce9c613e16459f6ae',
  measurementId: 'G-D0WFY9SHJ4',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
